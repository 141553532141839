import { acceptHMRUpdate, defineStore } from "pinia";

export const useTerraMovelV1Store = defineStore("terra-movel-v1-store", {
  state: () => ({
    _id: "",
    status: [],
    customer: {},
    address: {},
    credit_analysis: {},
    plan: {},
    payment: {},
    imei: "",
    msidn: "",
    changeTelephone: false,
    area_code: "",
    state: "",
    iccid: "",
    service: "",
  }),
  actions: {
    setOpeningSalePayload({
      cpf,
      service,
      imei = "",
      iccid = "",
      msidn = "",
      area_code = "",
      state = "",
      changeTelephone = false,
    }: OpeningSalePayload) {
      this.customer = {
        ...this.customer,
        cpf,
      };
      this.imei = imei;
      this.msidn = msidn;
      this.changeTelephone = changeTelephone;
      this.area_code = area_code;
      this.iccid = iccid;
      this.service = service;
      this.state = state;
    },

    setUserDataPayload(customer: any, address: any) {
      this.customer = {
        ...this.customer,
        ...customer,
      };
      this.address = {
        ...this.address,
        ...address,
      };
    },

    resetStore() {
      this.$reset();
    },

    setCreditAnalysis(credit_analysis: any) {
      console.log({
        credit_analysis,
      });
      this.credit_analysis = {
        ...this.credit_analysis,
        ...credit_analysis,
      };
    },
    setPlan(plan: any) {
      this.plan = {
        ...this.plan,
        ...plan,
      };
    },
    setCustomizeInvoicePayload(payload: any) {
      this.payment = {
        ...this.payment,
        ...payload,
      };
    },

    addSaleIdFromDraft(_id: string) {
      this._id = _id;
    },

    addSaleStatusFromDraft(status: any) {
      this.status = status;
    },

    async sendSaleToDraft(step: number, axios: any, status = null) {
      console.log("chamou aqui", this.payloadSale);
      return await axios.post("sales/draft", {
        sale: {
          ...this.payloadSale,
          step: step,
          type: "MOBILE",
          status: status || this.status,
          _id: this._id,
        },
      });
    },
    async sendCanceledSale(step: number, axios: any, status = "") {
      return await axios.post("sales/cancel", {
        sale: {
          ...this.payloadSale,
          step: step,
          type: "MOBILE",
          status,
          _id: this._id,
        },
      });
    },
  },
  getters: {
    payloadSale(): any {
      return {
        customer: this.customer,
        address: this.address,
        credit_analysis: this.credit_analysis,
        plan: this.plan,
        payment: this.payment,
        imei: this.imei,
        msidn: this.msidn,
        changeTelephone: this.changeTelephone,
        area_code: this.area_code,
        iccid: this.iccid,
        service: this.service,
        mobile: {
          provider: {
            name: "Terra",
            slug: "terra",
          },
        },
      };
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useTerraMovelV1Store, import.meta.hot),
  );
}

type OpeningSalePayload = {
  cpf: string;
  service: string;
  imei?: string;
  iccid?: string;
  msidn?: string;
  area_code?: string;
  changeTelephone?: boolean;
  state?: string;
};
